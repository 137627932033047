









import {Vue, Component} from 'vue-property-decorator';
import {Getter} from 'vuex-class';
import Page from '@/views/Page.vue';
import { User } from "@/models";

@Component({components: { Page,  }})
export default class Settings extends Vue {
  @Getter currentUser: User;

}
